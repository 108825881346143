import { useState } from 'react';
import { particles_config } from '../particles-config';
import Particles from "react-particles";
import { useCallback } from "react";
import { loadSlim } from "tsparticles-slim";
import { useLocation } from 'react-router-dom';

export function PContainer() {
    const particlesInit = useCallback(async engine => {
        await loadSlim(engine);
    }, []);

    const [location, setLocation] = useState(useLocation().pathname);

    if (location === "/storage" || location.match(/\/storage(\/\w+)/g)) {
        return (null);
    }

    return (
        <div className="particlesbox">
            <Particles id="starfield" init={particlesInit} options={particles_config} />
        </div>
    );
}