import { Fragment } from "react";
import { TextBlurb } from './TextBlurb';
import { Attribute } from './Attribute';
import { Icon } from './Dice';

function PrintStat({ stat, val }) {
    return (
        <div className="grid grid-col-1 w-20 h-fit m-2 text-center">
            <Icon type={stat} className="h-20" />
            <p className="text-2xl">{val}</p>
        </div>
    );
}

export function Subtype({ subtype }) {
    return (
        <Fragment>
            <div className="px-8">
                <h3 className="text-2xl lg:text-3xl ml-5 mt-8">{subtype.name}</h3>
                <h4 className="text-xl md:ml-10 lg:ml-20"><i>{subtype.subtitle}</i></h4>

                {subtype.detail.map((p) => (
                    <TextBlurb p={p} />
                ))}

                <h4 className="text-2xl mt-8">Attributes</h4>

                {subtype.attributes.map((at) => (
                    <Attribute key={at.name} at={at} />
                ))}

                <h4 className="text-2xl mt-8">Stat Modifications</h4>

                <div className="grid grid-col-1 w-full place-content-center">
                    <div className="flex flex-row flex-wrap mt-8 md:w-full justify-center">
                        {Object.entries(subtype.stats.self).map((stat) => (
                            <PrintStat key={stat[0]} stat={stat[0]} val={stat[1]} />
                        ))}

                        {Object.entries(subtype.stats.base).map((stat) => (
                            <PrintStat key={stat[0]} stat={stat[0]} val={stat[1]} />
                        ))}

                        {Object.entries(subtype.stats.env).map((stat) => (
                            <PrintStat key={stat[0]} stat={stat[0]} val={stat[1]} />
                        ))}
                    </div>
                </div>

                <h4 className="text-2xl mt-8">The Beginning</h4>
                {subtype.start_gear.map((p) => (
                    <TextBlurb p={p} />
                ))}

                <blockquote className="text-md italic font-semibold text-left text-zinc-400 text-center mt-5">
                    <p>"{subtype.quote}"</p>
                </blockquote>
            </div>

            <hr className="my-5 w-80 md:w-4/5 mx-auto" />
        </Fragment>
    );
}