import { Fragment } from "react";

import {
  faMeteor,
  faPlus,
  faMinus,
  faExplosion,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHandFist as Mox,
  faHandsBubbles as Dex,
  faUserShield as Fort,
  faInstitution as Acu,
  faGraduationCap as Wit,
  faPeopleArrows as Pres,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMountainSun as Land,
  faWater as Swim,
  faPlaneDeparture as Atmos,
  faRocket as Space,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFire as Heat,
  faIcicles as Cold,
  faBiohazard as Bio,
  faRadiation as Rad,
  faHandHoldingHand as Phys,
  faBolt as Elec,
  faGaugeHigh as Grav,
  faStarHalfStroke as Cos,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeartPulse as Health,
  faHeartCircleBolt as EAC,
  faShieldHalved as KAC,
  faStar as Stunt,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Icon({ type, color }) {
  var icon = "";

  switch (type) {
    case "Health":
      icon = Health;
      break;
    case "KAC":
      icon = KAC;
      break;
    case "EAC":
      icon = EAC;
      break;
    case "Stunt":
      icon = Stunt;
      break;
    case "Land":
      icon = Land;
      break;
    case "Swim":
      icon = Swim;
      break;
    case "Atmos":
      icon = Atmos;
      break;
    case "Space":
      icon = Space;
      break;
    case "Mox":
      icon = Mox;
      break;
    case "Dex":
      icon = Dex;
      break;
    case "Fort":
      icon = Fort;
      break;
    case "Acu":
      icon = Acu;
      break;
    case "Wit":
      icon = Wit;
      break;
    case "Pres":
      icon = Pres;
      break;
    case "Heat":
      icon = Heat;
      break;
    case "Cold":
      icon = Cold;
      break;
    case "Bio":
      icon = Bio;
      break;
    case "Rad":
      icon = Rad;
      break;
    case "Phys":
      icon = Phys;
      break;
    case "Elec":
      icon = Elec;
      break;
    case "Grav":
      icon = Grav;
      break;
    case "Cos":
      icon = Cos;
      break;
    default:
      icon = "";
  }

  return (
    <Fragment>
      {icon === "" ? null : (
        <FontAwesomeIcon
          className={color === "black" ? "text-black" : "text-white"}
          icon={icon}
        />
      )}
    </Fragment>
  );
}

export function NavDice({ type, value }) {
  return null;
  // roll until fail : value under d8 value
  //     let content = [];
  //                         for (let i = 1; i <= 5; i++) {
  //                             content.push(<NavDice type={type} value={amount} />);
  //                         }
  //                         return content;
}

export function BaseDice({ value }) {
  var icon = "";

  switch (value) {
    case 1:
      icon = faExplosion;
      break;
    case 2:
      icon = faMinus;
      break;
    case 3:
      icon = "";
      break;
    case 4:
      icon = "";
      break;
    case 5:
      icon = faPlus;
      break;
    case 6:
      icon = faMeteor;
      break;
    default:
      icon = "";
  }

  return (
    <div>
      {icon === "" ? null : (
        <FontAwesomeIcon className="text-white" icon={icon} />
      )}
    </div>
  );
}

export function EnvDice({ type, resist }) {
  return (
    <div className="grid w-24 h-24 die">
      {Math.floor(Math.random() * 13) <= resist ? (
        <div className="z-10 text-5xl -ml-1 -mt-1">
          <Icon type={type} />
        </div>
      ) : null}

      <svg className="z-0" viewBox="0 0 75 75">
        <path
          d="m5,22 18,-18 28,0 18,18 0,28 -18,18, -28,0 -18,-18z"
          stroke="white"
          fill="black"
        />
      </svg>
    </div>
  );
}
