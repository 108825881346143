export const ocs = {

    jaz: {
        owner: 'TheRoleplayer',
        name: 'Jaz',
        race: 'Gao',
        pronouns: 'she/her',

        profile_img: 'icon.png',
        ref_img: '',
        background_colour: '#290736',
        text_colour: '#ff58e7',

        blurb: 'A charismatic and determined Gao, Jaz has found her place as a Human mimic that delights in titillating the senses of the weak-willed. With a moderate credit flow and adventurous spirit, she joins her girlfriend on her galactic journey, doting and supporting her along the way.',

        meta_data: [],

        physical_data: [],

        img_gallery: [
            {
                url: "jammy_syndicato.png",
                anchor: "bg-top"
            },
            {
                url: "jammy_unknown.png",
                anchor: "bg-center"
            },
            {
                url: "jaz_buildingstarships.png",
                anchor: "bg-center"
            },
            {
                url: "solarpunkissci-ficottagecore_side-salt.png",
                anchor: "bg-center"
            },
            {
                url: "spacelesbians_catniped.png",
                anchor: "bg-center"
            },
            {
                url: "tammy_salriaah.jpg",
                anchor: "bg-center"
            },
        ],

        ad_gallery: []
    },

};

// CHAR: {
//     owner: '',
//     name: '',
//     race: '',
//     pronouns: '',
//
//     profile_img: '',
//     ref_img: '',
//     background_colour: '#000000',
//     text_colour: '#FFFFFF',
//
//     blurb: '',
//
//     meta_data: [],
//
//     physical_data: [],
//
//     img_gallery: [],
//
//     ad_gallery: []
// }
