import { Fragment } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExplosion } from '@fortawesome/free-solid-svg-icons';

export function StorageLanding() {
    return (
        <Fragment>
            <div className="App h-auto">
                <div className="container sm:w-screen lg:w-3/4 h-auto z-50">
                    <div className="w-full items-center text-center">
                        <h1 className="text-7xl my-8"><FontAwesomeIcon className="w-20 h-auto text-white mt-5" icon={faExplosion} /></h1>
                        <p className="m-3">Greetings, traveller. Searching for something?</p>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}